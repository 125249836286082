:root{
   
    --primary-ems-color:#3D5EE1;
    --secondary-ems-color:#18aefa;
}
html {
    position: relative;
    min-height: 100%;
  
  }
body {
   
    color: #333!important;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    height: 100%;
    line-height: 1.5!important;
   
    overflow-y: auto!important;
    padding-right: 0px!important;
    padding-bottom: 0!important;
}
.text-ems-primary{
    color:var(--primary-ems-color);
}
.border-ems-primary{
    border-color: var(--primary-ems-color)!important;
}
.bg-ems-primary{
    background-color:var(--primary-ems-color)!important;
}

.bg-ems-secondary{
    background-color:var(--secondary-ems-color)!important;
}
.text-ems-secondary{
  color:var(--secondary-ems-color);
}

.card.bg-comman {
    background-color: #fff;
    border: 0!important;
    border-radius: 15px;
    box-shadow: 0px 0px 31px rgba(44, 50, 63, 0.02);
}

.card.card-chart, .card.comman-shadow {
    background: #FFFFFF;
    box-shadow: 0px 0px 31px rgba(44, 50, 63, 0.02);
    border-radius: 15px;
    border: 0;
}
@media (min-width: 992px) {
   .my-container .container-fluid {
      padding-left: 210px;
      padding-right: 0px;
      margin-left: inherit;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s;
    }
}
.slow-animation{
    -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s;
}
    
/* width */
::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d1d1d1; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1; 
    border-radius: 10px;
}

/*table*/
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--primary-ems-color)!important;
    border-color: var(--primary-ems-color)!important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--primary-ems-color)!important;
    background: var(--primary-ems-color)!important;
}
.p-datatable .p-datatable-tbody > tr > td,.p-datatable .p-paginator-bottom
{
    border-width: 0px!important;
}
.p-datatable .p-datatable-thead>tr>th{
  font-weight: 700 !important;
  background: rgba(0, 0, 0, 0.05) !important;
  border: none !important;
  padding: 0.75rem 0.50rem!important;
  white-space: nowrap!important;
  border-top: 1px solid #e2e5e8!important;

}
.p-datatable .p-datatable-tbody>tr>td {
    padding: 0.75rem 0.50rem!important;
    white-space: nowrap!important;
}
/*from input*/
.login-danger{
    color: red;
}

.p-datatable-scrollable-table>.p-datatable-thead {

    z-index: 0!important;
}



.bootstrap-autocomplete {
    display: block!important;
    width: 100%!important;
    padding: 0.375rem 0.75rem!important;
    font-size: 1rem!important;
    line-height: 1.5!important;
    color: #495057!important;
    background-color: #fff!important;
    background-clip: padding-box!important;
    border: 1px solid #ced4da!important;
    border-radius: 0.25rem!important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out!important;
  }
  
  .bootstrap-autocomplete:focus {
    color: #495057!important;
    background-color: #fff!important;
    border-color: #80bdff!important;
    outline: 0!important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)!important;
  }
  
  .bootstrap-autocomplete::-ms-expand {
    background-color: transparent!important;
    border: 0!important;
  }
  
  .bootstrap-autocomplete::placeholder {
    color: #6c757d!important;
    opacity: 1!important;
  }
  
  .bootstrap-autocomplete:disabled, .form-control[readonly] {
    background-color: #e9ecef!important;
    opacity: 1!important;
  }
  /*table color*/
  .actions a {
    width: 32px;
    height: 32px;
   
}

.bg-success-light {
    background-color: #F7F7FA !important;
    border-radius: 50px;
    color: var(--primary-ems-color) !important;
}
